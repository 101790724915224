<template>
  <css-doodle ref="doodle" />
</template>

<script>
import { get } from 'vuex-pathify' // eslint-disable-line
/* eslint-enable */
import patternData from '@/assets/doodles/dynamic'
/* eslint-disable */
const Doodle = process.browser ? require('css-doodle') : undefined

export default {
  name: 'Doodle',

  props: {
    color: {
      type: String,
      default: 'black',
    },

    pattern: {
      type: Object,
      default: () => ({ name: 'maze' }),
    },
  },

  data() {
    return {
      doodle: null,
      patternData: null,
    }
  },

  computed: {
    pageId: get('page/@obj.id'),
  },

  watch: {
    pattern() {
      this.renderPattern()
    },

    color(val) {
      this.renderPattern()
    },
  },

  mounted() {
    this.doodle = this.$refs.doodle
    this.renderPattern()
  },

  methods: {
    renderPattern() {
      this.doodle.seed = this.pattern.seed
      const opts = { ...{ color: this.color }, ...this.pattern.opts }
      // this.patternData =
      this.doodle.update(patternData(opts))
    },
  },
}
</script>
