const shapes = {
  triangle: 'polygon(50% 0%, 0% 100%, 100% 100%)',
  circle: 'circle(50% at 50% 50%)',
  square: 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)',
  ellipse: 'ellipse(10% 40% at 50% 50%)',
}

const justify = {
  left: 'start',
  'top-left': 'start',
  'bottom-left': 'start',
  right: 'end',
  'top-right': 'end',
  'bottom-right': 'end',
}

const align = {
  top: 'start',
  'top-left': 'start',
  'top-right': 'start',
  bottom: 'end',
  'bottom-left': 'end',
  'bottom-right': 'end',
}

const rotate = {
  'top-left': 'rotateX(-45deg) rotateY(45deg)',
  top: 'rotateX(-45deg)',
  'top-right': 'rotateX(-45deg)  rotateY(-45deg)',
  left: 'rotateY(45deg)',
  right: 'rotateY(-45deg)',
  'bottom-left': 'rotateX(45deg) rotateY(45deg)',
  bottom: 'rotateX(45deg)',
  'bottom-right': 'rotateX(45deg) rotateY(-45deg)',
}

const transformOrigin = {
  'top-left': '0 0%',
  top: '50% 0%',
  'top-right': '100% 0%',
  left: '0 50%',
  center: '50%',
  right: '100% 50%',
  'bottom-left': '0 100%',
  bottom: '50% 100%',
  'bottom-right': '100% 100%',
}

export default function ({
  color,
  shape,
  size,
  rotation,
  shift,
  attachment,
  numItems = 10,
  spacing,
  noRepeat,
  bgPosition,
  randomItemSize,
  randomItemOpacity,
  randomItemRotation,
  containerRotation,
  margin,
  pos3d,
} = {}) {
  numItems = noRepeat ? numItems : 32

  const transform3d = pos3d ? `perspective(600px) ${rotate[pos3d]}` : ''

  const bgSize = !noRepeat ? 'cover' : '100%'
  const bgRepeat = noRepeat ? 'no-repeat' : 'repeat'

  const itemSize = randomItemSize ? 'scale(@rand(0.1, 2))' : ''

  const itemRotation = randomItemRotation
    ? 'rotate(@r(0deg, 360deg))'
    : rotation
    ? `rotate(${rotation}deg)`
    : ''

  let cellShiftTransform = ''

  if (shift) {
    const shiftCalc = `calc(-50% - ${spacing / 2}px)`
    cellShiftTransform =
      shift === 'cols'
        ? `@col(even) {
            transform:translateY(${shiftCalc}) ${itemRotation} ${itemSize};
          }`
        : `@row(even) {
            transform:translateX(${shiftCalc}) ${itemRotation} ${itemSize};
          }`
  }

  return `
    :doodle {
      @grid: 1 / 100%;
    }

    :container {
      overflow:hidden;
    }

    transform:rotate(${containerRotation}deg);
    transform-origin: ${transformOrigin[bgPosition]};
    transform: ${transform3d};

    background-size:${bgSize};
    background-repeat:${bgRepeat};
    background-attachment:${attachment || 'scroll'};
    background-position: center;
    background-image: @doodle(
    
      :doodle {
        height:100%;
        @grid: ${numItems} ;
      }
      
      :container {
        grid-gap:${noRepeat ? spacing + 'px' : ''};
        grid-template-columns: ${
          noRepeat ? `repeat(${numItems},${size}px)` : ''
        };
        grid-template-rows: ${noRepeat ? `repeat(${numItems},${size}px)` : ''};
        padding:${noRepeat ? `${margin}rem` : ''};
        justify-content:${justify[bgPosition] ?? 'center'};
        align-content:${align[bgPosition] ?? 'center'};
      }
      
      width: ${size}px;
      height: ${size}px;
      margin: ${spacing / 2}px;
      
      clip-path: ${
        shape === 'mix'
          ? `@p(${shapes.triangle},${shapes.square},${shapes.circle})`
          : shapes[shape]
      };

      transform: ${itemRotation} ${itemSize};

      ${cellShiftTransform}

      opacity: ${randomItemOpacity ? '@rand(0.01, 0.1)' : 0.06};
      background-color:${color};
    );
  `
}
